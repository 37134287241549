import { createGlobalStyle } from 'styled-components';
import 'normalize.css';

export default createGlobalStyle`
  html, body, #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
    font-size: 16px;
  }
  
  body {
    font-family: ${props => props.theme.typo.main.fontFamily};
    font-size: ${props => props.theme.typo.main.fontSize};
    font-weight: ${props => props.theme.typo.main.fontWeight};
    line-height: ${props => props.theme.typo.main.lineHeight};
    letter-spacing: ${props => props.theme.typo.main.letterSpacing};
  }
  
  p, h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }  
`;
