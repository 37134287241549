import styled, { ThemeProvider } from 'styled-components';
import React, { useState } from 'react';
import { darkTheme, lightTheme } from '../theme/themes';
import GlobalStyle from '../theme/styles';
import ThemeSwitch from './theme-switch';
import Hamburger from './hamburger';
import Menu from './menu';

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  background-color: ${props => props.theme.palette.primary.main};
  transition: background ${props => props.theme.animate.theme.duration};
  transition-timing-function: ${props => props.theme.animate.theme.function};
`;

const SwitchWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 0;
  transition-property: opacity;
  transition-duration: ${props =>
    props.theme.animate.bodyLoaded.switch.duration};
  transition-timing-function: ${props =>
    props.theme.animate.bodyLoaded.switch.function};
  transition-delay: ${props => props.theme.animate.bodyLoaded.switch.delay};

  .body--loaded & {
    opacity: 1;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    top: 32px;
    right: 32px;
  }
`;

const HamburgerWrapper = styled.div`
  position: absolute;
  top: 34px;
  left: 30px;
  z-index: ${props => props.theme.zIndex.ham};
  opacity: 0;

  transition-duration: ${props =>
    props.theme.animate.bodyLoaded.switch.duration};
  transition-timing-function: ${props =>
    props.theme.animate.bodyLoaded.switch.function};
  transition-delay: ${props => props.theme.animate.bodyLoaded.switch.delay};

  .body--loaded & {
    opacity: 1;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    top: 50%;
    transform: translateY(-50%);
    left: 42px;
  }
`;

const Layout = ({ children }) => {
  const [theme, setTheme] = useState(darkTheme);
  const [menuOpen, setMenuOpen] = useState(false);

  const onThemeChange = () => {
    if (theme.id === darkTheme.id) {
      setTheme(lightTheme);
      return;
    }

    setTheme(darkTheme);
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Wrapper>
        <SwitchWrapper>
          <ThemeSwitch onChange={onThemeChange} />
        </SwitchWrapper>
        <HamburgerWrapper>
          <Hamburger isOpen={menuOpen} onClick={setMenuOpen} />
        </HamburgerWrapper>
        <Menu isOpen={menuOpen} />
        {children}
      </Wrapper>
    </ThemeProvider>
  );
};

export default Layout;
