import React from 'react';
import styled from 'styled-components';
import Copyright from './copyright';

const Wrapper = styled.div`
  position: relative;
  max-width: 1000px;
  color: ${props => props.theme.palette.white};
`;

const About = styled.p`
  margin-bottom: 64px;

  font-family: ${props => props.theme.typo.accent.fontFamily};
  font-size: 2.4rem;
  line-height: 1.4;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 3.5rem;
    line-height: 1.25;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    font-size: 3.5rem;
  }
`;

const AddInfoWrapper = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    display: flex;
  }
`;

const Text = styled.p`
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;

  font-family: ${props => props.theme.typo.main.fontFamily};
  font-size: 1.7rem;
  line-height: 1.5;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 2.5rem;
    line-height: 1.35;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    margin-bottom: 0;
    margin-right: 88px;
  }
`;

const Connect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  font-family: ${props => props.theme.typo.main.fontFamily};
  font-size: 1.7rem;
  line-height: 1.5;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 2.5rem;
    line-height: 1.4;
  }
`;

const CopyrightWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: -128px;
`;

const Link = styled.a`
  display: block;
  padding-bottom: 4px;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    padding-bottom: 8px;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.palette.white};
  transition: transform 1s;
`;

const Line1 = styled(Line)`
  transform: translateX(0);
`;

const Line2 = styled(Line)`
  position: relative;
  bottom: 0;
  transform: translateX(-200%);
`;

const Email = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: 24px;

  &:hover ${Line1} {
    transform: translateX(200%);
  }

  &:hover ${Line2} {
    transform: translateX(0);
  }
`;

const Phone = styled.div`
  position: relative;
  overflow: hidden;

  &:hover ${Line1} {
    transform: translateX(200%);
  }

  &:hover ${Line2} {
    transform: translateX(0);
  }
`;

export default function Contacts() {
  return (
    <Wrapper>
      <About>
        Develop learning platforms, draw up syllabuses, promote and conduct
        training courses.
      </About>
      <AddInfoWrapper>
        <Text>
          <span>At Daat Agency,</span>
          <span>we speak English,</span>
          <span>Russian and Ukrainian.</span>
        </Text>
        <Connect>
          <Email>
            <Link href="mailto:hello@daat.agency" target="_blank">
              hello@daat.agency
            </Link>
            <Line1 />
            <Line2 />
          </Email>
          <Phone>
            <Link href="tel:+380508658218">+38 (050) 865-82-18</Link>
            <Line1 />
            <Line2 />
          </Phone>
        </Connect>
        <CopyrightWrapper>
          <Copyright />
        </CopyrightWrapper>
      </AddInfoWrapper>
    </Wrapper>
  );
}
