import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  width: 30px;
  height: 18px;
  cursor: pointer;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    width: 50px;
    height: 22px;
  }
`;

const Line = styled.div`
  position: absolute;
  width: 50%;
  height: 2px;
  background-color: ${props => props.theme.palette.secondary.main};
  transform: rotate(0deg);
  transition-property: background-color, transform, left, opacity;

  transition-duration: ${props => props.theme.animate.theme.duration},
    ${props => props.theme.animate.ham.duration},
    ${props => props.theme.animate.ham.duration},
    ${props => props.theme.animate.ham.duration};

  transition-timing-function: ${props => props.theme.animate.theme.function},
    ${props => props.theme.animate.ham.function},
    ${props => props.theme.animate.ham.function},
    ${props => props.theme.animate.ham.function};

  &:nth-child(even) {
    left: 50%;
  }

  &:nth-child(odd) {
    left: 0;
  }

  &:nth-child(1),
  :nth-child(2) {
    top: 0;
  }

  &:nth-child(3),
  :nth-child(4) {
    top: 8px;
  }

  &:nth-child(5),
  :nth-child(6) {
    top: 16px;
  }

  &.open:nth-child(3) {
    left: -100%;
    opacity: 0;
  }

  &.open:nth-child(4) {
    left: 150%;
    opacity: 0;
  }

  &.open:nth-child(1) {
    background-color: ${props => props.theme.palette.white};
    transform: rotate(45deg);
    left: 2.5px;
    top: 5px;
  }

  &.open:nth-child(2) {
    background-color: ${props => props.theme.palette.white};
    transform: rotate(-45deg);
    left: calc(50% - 2.5px);
    top: 5px;
  }

  &.open:nth-child(5) {
    background-color: ${props => props.theme.palette.white};
    transform: rotate(-45deg);
    left: 2.5px;
    top: 15px;
  }

  &.open:nth-child(6) {
    background-color: ${props => props.theme.palette.white};
    transform: rotate(45deg);
    left: calc(50% - 2.5px);
    top: 15px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    &:nth-child(3),
    :nth-child(4) {
      top: 10px;
    }

    &:nth-child(5),
    :nth-child(6) {
      top: 20px;
    }

    &.open:nth-child(1) {
      left: 3.5px;
      top: 1px;
    }

    &.open:nth-child(2) {
      left: calc(50% - 3.5px);
      top: 1px;
    }

    &.open:nth-child(5) {
      left: 3.5px;
      top: 19px;
    }

    &.open:nth-child(6) {
      left: calc(50% - 3.5px);
      top: 19px;
    }
  }
`;

export default function Hamburger({ isOpen, onClick }) {
  return (
    <Wrapper onClick={() => onClick(!isOpen)}>
      <Line className={isOpen ? 'open' : ''} />
      <Line className={isOpen ? 'open' : ''} />
      <Line className={isOpen ? 'open' : ''} />
      <Line className={isOpen ? 'open' : ''} />
      <Line className={isOpen ? 'open' : ''} />
      <Line className={isOpen ? 'open' : ''} />
    </Wrapper>
  );
}
