import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  font-family: ${props => props.theme.typo.main.fontFamily};
  font-size: ${props => props.theme.typo.main.caption};
  font-weight: ${props => props.theme.typo.main.fontWeight};
  color: ${props => props.theme.palette.white};
`;

export default function Contacts() {
  return <Wrapper>{`© Daat Agency, 2020-${new Date().getFullYear()}`}</Wrapper>;
}
