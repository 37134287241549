const breakpointsObj = {
  breakpoints: {
    xs: '480px',
    sm: '768px',
    md: '960px',
    lg: '1280px',
  },
};

const easeInCubic = 'cubic-bezier(0.32, 0, 0.67, 0)';

const typoObj = {
  typo: {
    main: {
      fontFamily: '"Open Sans", serif',
      fontWeight: 400,
      lineHeight: 1,
      letterSpacing: 'normal',

      body1: '1rem',
      caption: '0.9rem',
    },
    accent: {
      fontFamily: '"Philosopher", sans-serif',
      fontWeight: 400,
      lineHeight: 1,
      letterSpacing: 'normal',

      large: '0.8rem',
    },
  },
};

const zIndexObj = {
  zIndex: {
    ham: '100',
    menuLayout: '99',
  },
};

const commonColors = {
  grey: '#b3b8bb',
  greyDark: '#5d5d5d',
  white: '#fff',
};

const animateObj = {
  animate: {
    theme: {
      duration: '0.8s',
      function: 'ease-in-out',
    },
    ham: {
      duration: '0.3s',
      function: 'ease-in-out',
    },
    switch: {
      duration: '0.5s',
      function: 'ease-in-out',
    },
    menu: {
      duration: '0.8s',
      function: 'cubic-bezier(1,0,.57,1)',
    },
    letter: {
      duration: '0.8s',
      function: easeInCubic,
      delay: '2s',
    },
    bodyLoaded: {
      title: {
        duration: '1.5s',
        function: easeInCubic,
      },
      ham: {
        duration: '0.8s',
        function: easeInCubic,
        delay: '3.5s',
      },
      switch: {
        duration: '0.8s',
        function: easeInCubic,
        delay: '3.5s',
      },
      subtitle: {
        duration: '1.2s',
        function: easeInCubic,
        delay: '2.5s',
      },
    },
  },
};

export const darkTheme = {
  id: 'dark',
  palette: {
    primary: {
      main: '#000',
      text: '#fff',
    },
    secondary: {
      main: '#fff',
      text: '#000',
    },
    ...commonColors,
  },
  ...animateObj,
  ...breakpointsObj,
  ...zIndexObj,
  ...typoObj,
};

export const lightTheme = {
  id: 'light',
  palette: {
    primary: {
      main: '#fff',
      text: '#000',
    },
    secondary: {
      main: '#000',
      text: '#fff',
    },
    ...commonColors,
  },
  ...animateObj,
  ...breakpointsObj,
  ...zIndexObj,
  ...typoObj,
};
