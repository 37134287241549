import React from 'react';
import styled from 'styled-components';

const Switch = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  cursor: pointer;
  transition: transform;
  transition-duration: ${props => props.theme.animate.switch.duration};
  transition-timing-function: ${props => props.theme.animate.switch.function};

  &:hover {
    transform: rotate(180deg);
  }
`;

const SwitchIcon = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.palette.secondary.main};
  background: linear-gradient(
    to right,
    ${props => props.theme.palette.primary.main} 50%,
    ${props => props.theme.palette.secondary.main} 50%
  );
  transition: border ${props => props.theme.animate.theme.duration}
    ${props => props.theme.animate.theme.function};
`;

export default function ThemeSwitch({ onChange }) {
  return (
    <Switch onClick={onChange}>
      <SwitchIcon />
    </Switch>
  );
}
