import React from 'react';
import styled from 'styled-components';
import Contacts from './contacts';

const Wrapper = styled.section`
  box-sizing: border-box;
  position: fixed;
  left: 0;
  top: 0;
  min-width: 100vw;
  min-height: 100vh;

  display: flex;
  align-items: center;
  padding: 120px 32px 0;
  flex-direction: column;

  background-color: ${props => props.theme.palette.grey};
  transform: translateX(-100vw);
  transition: transform ${props => props.theme.animate.menu.duration}
    ${props => props.theme.animate.menu.function};
  z-index: ${props => props.theme.zIndex.menuLayout};

  &.open {
    transform: translateX(0);
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    padding: 0;
  }
`;

const ContactsWrapper = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    margin-top: 48vh;
    transform: translateY(-50%);
  }
`;

const Menu = ({ isOpen }) => {
  return (
    <Wrapper className={isOpen ? 'open' : ''}>
      <ContactsWrapper>
        <Contacts />
      </ContactsWrapper>
    </Wrapper>
  );
};

export default Menu;
